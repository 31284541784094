@media print {
  .hide-on-print {
    display: none !important;
  }

  @page {
    margin: 10mm 0 10mm 0;
    size: auto;
  }
}
