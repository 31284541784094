.icon-success {
  color: var(--color-icon-success);
}

.filled-success-icon {
  align-self: center;
  background-color: var(--color-icon-filled-success-bg);
  border-radius: var(--radius-100); 
  color: var(--color-icon-filled-success) !important;
}
