// Entry point for your Sass build
@use "@material/button";
@use "@material/button/styles" as mdc-button;
@use "@material/checkbox";
@use "@material/chips/deprecated";
@use "@material/chips/deprecated/mdc-chips";
@use "@material/circular-progress/mdc-circular-progress";
@use "@material/data-table/data-table";
@use "@material/dialog";
@use "@material/drawer";
@use "@material/fab";
@use "@material/floating-label/mdc-floating-label";
@use "@material/form-field";
@use "@material/icon-button";
@use "@material/icon-button/styles";
@use '@material/layout-grid/mdc-layout-grid';
@use "@material/line-ripple/mdc-line-ripple";
@use "@material/linear-progress";
@use "@material/list/evolution-mixins" as list-evolution-mixins;
@use "@material/list";
@use "@material/list/mdc-list";
@use "@material/menu-surface/mdc-menu-surface";
@use "@material/menu/mdc-menu";
@use "@material/notched-outline/mdc-notched-outline";
@use "@material/radio";
@use "@material/radio/styles" as mdc-radio;
@use "@material/select";
@use "@material/select/mdc-select";
@use "@material/slider";
@use "@material/slider/styles" as mdc-slider;
@use "@material/snackbar";
@use "@material/snackbar/mdc-snackbar";
@use "@material/switch/deprecated" as switch;
@use "@material/switch/deprecated/mdc-switch-deprecated";
@use "@material/tab";
@use "@material/tab-bar/mdc-tab-bar";
@use "@material/tab-indicator";
@use "@material/tab-indicator/mdc-tab-indicator";
@use "@material/tab-scroller/mdc-tab-scroller";
@use "@material/tab/mdc-tab";
@use "@material/textfield";
@use "@material/top-app-bar";
@use "@material/top-app-bar/mdc-top-app-bar";
@use "@material/tooltip/styles" as mdc-tooltip;

@include checkbox.core-styles;
@include data-table.core-styles;
@include data-table.theme-baseline;
@include dialog.core-styles;
@include drawer.core-styles;
@include drawer.modal-core-styles;
@include drawer.dismissible-core-styles;
@include fab.core-styles;
@include form-field.core-styles;
@include linear-progress.core-styles;
@include list-evolution-mixins.core-styles();
@include switch.core-styles;
@include textfield.core-styles;

@import './modes';
@import './light-color-scheme';
@import './dark-color-scheme';
@import './variables';

@import './styles/color-scheme';
@import './styles/color-scheme-light';
@import './styles/color-scheme-dark';
@import './styles/elevation';
@import './styles/radius';

@import 'material-icons/iconfont/filled.css';
@import 'material-icons/iconfont/outlined.css';

@import './components/animation';
@import './components/banner';
@import './components/button';
@import './components/chip';
@import './components/dialog';
@import './components/dropdown';
@import './components/icon';
@import './components/input';
@import './components/keyboard-key';
@import './components/list';
@import './components/mark';
@import './components/mention';
@import './components/outline';
@import './components/progress-bar';
@import './components/question-button';
@import './components/search-bar';
@import './components/select-button';
@import './components/side-panel';
@import './components/snackbar';
@import './components/split-screen';
@import './components/support-button';
@import './components/text';
@import './components/tooltip';

@import './materialio/mdc-button';
@import './materialio/mdc-checkbox';
@import './materialio/mdc-chip';
@import './materialio/mdc-circular-progress';
@import './materialio/mdc-data-table';
@import './materialio/mdc-dialog';
@import './materialio/mdc-drawer';
@import './materialio/mdc-fab';
@import './materialio/mdc-form-field';
@import './materialio/mdc-icon';
@import './materialio/mdc-icon-button';
@import './materialio/mdc-linear-progress';
@import './materialio/mdc-list';
@import './materialio/mdc-radio';
@import './materialio/mdc-select';
@import './materialio/mdc-slider';
@import './materialio/mdc-snackbar';
@import './materialio/mdc-switch';
@import './materialio/mdc-tab';
@import './materialio/mdc-tab-indicator';
@import './materialio/mdc-text-field';
@import './materialio/mdc-tooltip';
@import './materialio/mdc-top-app-bar';
@import './materialio/overwrites';

@import 'highlight.js/styles/monokai';
@import 'glider-js/glider';
@import './vendor/glider-overwrites';
@import 'flatpickr/dist/flatpickr';

@import './redactor/audio-recording';
@import './redactor/equation';
@import './redactor/filemanager';
@import './redactor/handle';
@import './redactor/option-list';
@import './redactor/redactor';
@import './redactor/redactor-overwrites';
@import './redactor/shortcut';
@import './redactor/shortcut-autocomplete';
@import './redactor/variable';
@import './redactor/webcam-screenshot';

@import './mathlive/mathlive-overwrites';

@import 'katex/dist/katex.min.css';

@import './base';
@import './blankslate';
@import './borders';
@import './button';
@import './colors';
@import './container';
@import './flexbox';
@import './flash';
@import './gap';
@import './layout';
@import './margin';
@import './padding';
@import './position';
@import './truncate';
@import './turbo';
@import './typography';

@import './accessibility';
@import './answer';
@import './attachment';
@import './badge';
@import './banner';
@import './box';
@import './breadcrumb';
@import './canvas';
@import './card';
@import './chart';
@import './chat';
@import './choice';
@import './circle';
@import './cookie-notice';
@import './criterium';
@import './domain';
@import './filter';
@import './flatpickr';
@import './highlights';
@import './histogram';
@import './horizontal-scroll';
@import './hotspot-area';
@import './icons';
@import './indicator';
@import './label';
@import './loading';
@import './log';
@import './match-column';
@import './match-row';
@import './media';
@import './menu';
@import './note';
@import './objective';
@import './page';
@import './pages';
@import './pincode';
@import './print';
@import './question';
@import './relation';
@import './result';
@import './scan-errors';
@import './scans';
@import './separator';
@import './sidenav';
@import './split-buttons';
@import './subelement';
@import './subquestion';
@import './summary';
@import './survey';
@import './table';
@import './tag';
@import './task';
@import './tile';
@import './timeline';
@import './timeslot';
@import './user_icon';
@import './versions';
@import './warning';
@import './width';

// legends
@import './legends/certainty-levels';

// Grading
@import './grading/adjustment';

// Quiz
@import './quiz/calculator';
@import './quiz/code';
@import './quiz/drawing';
@import './quiz/hotspot';
@import './quiz/kprime';
@import './quiz/match';
@import './quiz/navigation';
@import './quiz/notes';
@import './quiz/sortable';
@import './quiz/panel';
@import './quiz/worksheet';
@import './quiz/readspeaker';

@import './vendor/image-mapper';
